@mixin modulor-button {
  @include modulor-font;
  @include modulor-font-size(default);
  @include modulor-border(button);

  background-color: modulor-color(white);
  color: modulor-color(black);
  cursor: pointer;
  display: inline-block;
  padding: modulor-unit(padding-input);
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;

  &:disabled {
    color: modulor-color(gray);
    cursor: auto;
  }
}

@mixin modulor-button-as-link {
  @include modulor-link;
  
  background: none;
  border: 0;
  display: inline;
  margin: 0;
  padding: 0;

  &:hover {
    background: none;
  }
}
