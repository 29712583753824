@mixin modulor-checkbox-toggle($toggle-height: 1.4em, $toggle-padding: .18em, $color-off: modulor-color(red), $color-toggle: modulor-color(white), $color-on: modulor-color(green)) {
  input[type='checkbox'] {
    display: none;

    &:checked + span {
      background: $color-on;

      &:after {
        left: 50%;
      }
    }

    &:disabled + span {
      cursor: default;
      &:after { opacity: 0.5; }
    }
  }

  span {
    cursor: pointer;
    display: block;
    height: $toggle-height;
    padding: $toggle-padding;
    position: relative;
    transition: all .4s ease;
    width: calc(#{$toggle-height*2} - #{$toggle-padding*2});
    background: $color-off;
    border-radius: $toggle-height;
    outline: 0;
    user-select: none;

    &:after,
    &:before {
      content: '';
      display: block;
      height: 100%;
      position: relative;
      width: 50%;
    }

    &:before {
      display: none;
    }

    &:after {
      background: $color-toggle;
      border-radius: 50%;
      transition: all .2s ease;
      left: 0%;
    }

    &::selection {
      background: none;
    }
  }
}
