@mixin modulor-breakpoints($names: map-keys($modulor-breakpoints)) {
  @each $name in $names {
    $breakpoint: map-get($modulor_breakpoints, $name);
    $columns: map-get($breakpoint, columns) !global;
    $name: "#{$name}" !global;
    $unit: 100% / $columns !global;
    $query: "#{map-get($breakpoint, exclusive_query)}" !global;
    $typography: map-get($modulor-typography-breakpoints, $name) !global;

    @if str-length($query) > 0 {
      @media #{$query} { @content; }
    } @else {
      @content;
    };
  }
}
