@charset "UTF-8";
.modulor_button {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  letter-spacing: 0;
  border: 0.1rem solid #d7d7d7;
  border-radius: 0.2rem;
  background-color: white;
  color: black;
  cursor: pointer;
  display: inline-block;
  padding: 0.5em;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap; }
  @media all and (max-width: 1023px) {
    .modulor_button {
      font-size: 1.4rem;
      line-height: 1.6rem; } }
  @media (min-width: 1024px) and (max-width: 1679px) {
    .modulor_button {
      font-size: 1.4rem;
      line-height: 1.6rem; } }
  @media (min-width: 1680px) and (max-width: 1919px) {
    .modulor_button {
      font-size: 1.4rem;
      line-height: 1.6rem; } }
  @media all and (min-width: 1920px) {
    .modulor_button {
      font-size: 1.4rem;
      line-height: 1.6rem; } }
  .modulor_button:disabled {
    color: #aaaaaa;
    cursor: auto; }
  .modulor_button:focus {
    outline: 0.2rem solid #bcd6fd; }
  .modulor_button[data-confirm]:after {
    content: ' …'; }
