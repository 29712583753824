@mixin reset-dl {
  @include reset-margin;
  @include reset-padding;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  dt, dd {
    margin: 0;
    padding: 0;
  }
}
