@charset "UTF-8";
.exhibition_list_module__content[data-view-option="past"] {
  background-color: var(--color-gray, #c7c7c7);
  background-clip: padding-box; }
  .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__heading:not(:last-child) {
    margin-bottom: 0; }
  @media all and (max-width: 739px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__decades {
      margin-bottom: 2.25rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__decades {
      margin-bottom: 2.25rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__decades {
      margin-bottom: 2.4rem; } }
  @media (min-width: 1280px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__decades {
      margin-bottom: 2.7rem; } }
  .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__decade {
    white-space: nowrap; }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__decade:not(:last-child) {
      margin-right: 0.5em; }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__decade span {
      text-decoration: none;
      -webkit-print-color-adjust: exact;
      background-size: 100%;
      background-repeat: repeat-x;
      background-position: 100% 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0.1em, black 0.1em, black 0.16em, rgba(0, 0, 0, 0) 0.16em); }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__decade a {
      text-decoration: none; }
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__decade a:hover {
        text-decoration: none;
        -webkit-print-color-adjust: exact;
        background-size: 100%;
        background-repeat: repeat-x;
        background-position: 100% 0;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0.1em, black 0.1em, black 0.16em, rgba(0, 0, 0, 0) 0.16em); }
  @media all and (max-width: 739px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail {
      font-size: 2.08rem;
      line-height: 2.4rem;
      letter-spacing: 0; } }
  @media (min-width: 1280px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail {
      font-size: 2.34rem;
      line-height: 2.7rem;
      letter-spacing: 0; } }
  @media all and (max-width: 739px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail {
      width: calc(100% - 1.125rem); } }
  @media (min-width: 740px) and (max-width: 999px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail {
      width: calc(50% - 1.125rem); } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail {
      width: calc(50% - 1.2rem); } }
  @media (min-width: 1280px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail {
      width: calc(40% - 1.35rem); } }
  @media (min-width: 740px) and (max-width: 999px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail {
      width: 100%; } }
  @media all and (max-width: 739px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail {
      width: 100%; } }
  .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail img:not(.lazyloaded) {
    background: black; }
  @media all and (max-width: 739px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail__exhibition,
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail__date_range,
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail__location {
      font-size: 1.44rem;
      line-height: 1.76rem;
      letter-spacing: 0; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail__exhibition,
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail__date_range,
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail__location {
      font-size: 1.44rem;
      line-height: 1.76rem;
      letter-spacing: 0; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail__exhibition,
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail__date_range,
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail__location {
      font-size: 1.44rem;
      line-height: 1.76rem;
      letter-spacing: 0; } }
  @media (min-width: 1280px) {
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail__exhibition,
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail__date_range,
    .exhibition_list_module__content[data-view-option="past"] .exhibition_page__thumbnail__location {
      font-size: 1.62rem;
      line-height: 1.98rem;
      letter-spacing: 0; } }
  .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: normal; }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions em, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions i {
      font-family: ag-book-pro, sans-serif;
      font-weight: 400;
      font-style: italic; }
    @media all and (max-width: 739px) {
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions {
        font-size: 1.95rem;
        line-height: 2.25rem;
        letter-spacing: 0; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions {
        font-size: 1.95rem;
        line-height: 2.25rem;
        letter-spacing: 0; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions {
        font-size: 2.08rem;
        line-height: 2.4rem;
        letter-spacing: 0; } }
    @media (min-width: 1280px) {
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions {
        font-size: 2.34rem;
        line-height: 2.7rem;
        letter-spacing: 0; } }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions em, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions i {
      font-family: ag-book-pro, sans-serif;
      font-weight: 400;
      font-style: italic; }
    @media all and (max-width: 739px) {
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions {
        font-size: 1.95rem;
        line-height: 2.25rem;
        letter-spacing: 0; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions {
        font-size: 1.95rem;
        line-height: 2.25rem;
        letter-spacing: 0; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions {
        font-size: 2.08rem;
        line-height: 2.4rem;
        letter-spacing: 0; } }
    @media (min-width: 1280px) {
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions {
        font-size: 2.34rem;
        line-height: 2.7rem;
        letter-spacing: 0; } }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h1, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h2, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h3, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h4, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h5, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h6 {
      padding: 0;
      margin: 0;
      font-family: ag-book-pro, sans-serif;
      font-weight: 400;
      font-style: normal; }
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h1:not(:last-child), .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h2:not(:last-child), .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h3:not(:last-child), .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h4:not(:last-child), .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h5:not(:last-child), .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h6:not(:last-child) {
        margin-bottom: 1em; }
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h1 em, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h1 i, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h2 em, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h2 i, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h3 em, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h3 i, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h4 em, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h4 i, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h5 em, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h5 i, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h6 em, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h6 i {
        font-family: ag-book-pro, sans-serif;
        font-weight: 400;
        font-style: italic; }
      @media all and (max-width: 739px) {
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h1, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h2, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h3, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h4, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h5, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h6 {
          font-size: 1.95rem;
          line-height: 2.25rem;
          letter-spacing: 0; } }
      @media (min-width: 740px) and (max-width: 999px) {
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h1, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h2, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h3, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h4, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h5, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h6 {
          font-size: 1.95rem;
          line-height: 2.25rem;
          letter-spacing: 0; } }
      @media (min-width: 1000px) and (max-width: 1279px) {
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h1, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h2, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h3, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h4, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h5, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h6 {
          font-size: 2.08rem;
          line-height: 2.4rem;
          letter-spacing: 0; } }
      @media (min-width: 1280px) {
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h1, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h2, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h3, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h4, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h5, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h6 {
          font-size: 2.34rem;
          line-height: 2.7rem;
          letter-spacing: 0; } }
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h1:not(:first-child), .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h2:not(:first-child), .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h3:not(:first-child), .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h4:not(:first-child), .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h5:not(:first-child), .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h6:not(:first-child) {
        margin-top: 2em; }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h1, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions h2 {
      text-transform: uppercase; }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions a {
      text-decoration: none;
      color: black;
      text-decoration: none;
      -webkit-print-color-adjust: exact;
      background-size: 100%;
      background-repeat: repeat-x;
      background-position: 100% 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions a[href^='mailto:'], .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions a[href^='tel:'] {
        text-decoration: none;
        color: black;
        background: none; }
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions a[href^='mailto:']:hover, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions a[href^='tel:']:hover {
          text-decoration: none;
          -webkit-print-color-adjust: exact;
          background-size: 100%;
          background-repeat: repeat-x;
          background-position: 100% 0;
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions p {
      margin: 0;
      padding: 0; }
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions p:not(:last-child) {
        margin-bottom: 1em; }
    @media all and (max-width: 739px) {
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions br + small {
        margin-top: 0.49rem; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions br + small {
        margin-top: 0.49rem; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions br + small {
        margin-top: 0.64rem; } }
    @media (min-width: 1280px) {
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions br + small {
        margin-top: 0.72rem; } }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions small {
      display: block; }
      @media all and (max-width: 739px) {
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions small {
          font-size: 1.44rem;
          line-height: 1.76rem;
          letter-spacing: 0; } }
      @media (min-width: 740px) and (max-width: 999px) {
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions small {
          font-size: 1.44rem;
          line-height: 1.76rem;
          letter-spacing: 0; } }
      @media (min-width: 1000px) and (max-width: 1279px) {
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions small {
          font-size: 1.44rem;
          line-height: 1.76rem;
          letter-spacing: 0; } }
      @media (min-width: 1280px) {
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions small {
          font-size: 1.62rem;
          line-height: 1.98rem;
          letter-spacing: 0; } }
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions small a {
        text-decoration: none;
        color: black;
        text-decoration: none;
        -webkit-print-color-adjust: exact;
        background-size: 100%;
        background-repeat: repeat-x;
        background-position: 100% 0;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions small a[href^='mailto:'], .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions small a[href^='tel:'] {
          text-decoration: none;
          color: black;
          background: none; }
          .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions small a[href^='mailto:']:hover, .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions small a[href^='tel:']:hover {
            text-decoration: none;
            -webkit-print-color-adjust: exact;
            background-size: 100%;
            background-repeat: repeat-x;
            background-position: 100% 0;
            background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions ul {
      list-style: none;
      margin: 0;
      padding: 0; }
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions ul > li {
        margin: 0;
        padding: 0; }
      .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions ul li {
        padding-left: 1em; }
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions ul li:before {
          content: '– ';
          margin-left: -1em; }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions code,
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions sup {
      line-height: 0 !important; }
      @media all and (max-width: 739px) {
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions code,
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions sup {
          font-size: 1.44rem;
          line-height: 1.76rem;
          letter-spacing: 0; } }
      @media (min-width: 740px) and (max-width: 999px) {
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions code,
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions sup {
          font-size: 1.44rem;
          line-height: 1.76rem;
          letter-spacing: 0; } }
      @media (min-width: 1000px) and (max-width: 1279px) {
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions code,
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions sup {
          font-size: 1.44rem;
          line-height: 1.76rem;
          letter-spacing: 0; } }
      @media (min-width: 1280px) {
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions code,
        .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions sup {
          font-size: 1.62rem;
          line-height: 1.98rem;
          letter-spacing: 0; } }
    .exhibition_list_module__content[data-view-option="past"] .exhibition_list_module__past_exhibitions sup {
      font-size: 0.65em; }
