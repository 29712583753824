// TODO: document use

@mixin responsive_image {
  display: block;
  height: 0;
  overflow: hidden;
  position: relative;

  img {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &.lazyloaded {
      background: none;
    }
  }
}

// TODO: for backw-compat reasons
@mixin modulor_image_responsive {
   @include responsive_image;
}
