@import 'partials';

$breakpoints: (xs: (query: "all", exclusive_query: "all and (max-width: 739px)", columns: 2), s: (query: "(min-width: 740px)", exclusive_query: "(min-width: 740px) and (max-width: 999px)", columns: 4), m: (query: "(min-width: 1000px)", exclusive_query: "(min-width: 1000px) and (max-width: 1279px)", columns: 4), l: (query: "(min-width: 1280px)", exclusive_query: "(min-width: 1280px)", columns: 5));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Museum, anders_wohnen: Anders-Wohnen, cafe: Museum/Kaiser-Wilhelm-Museum/Cafe, collection: Collection, editions: Visit/Shop/Editions, education: Education, engagement: Engagement, exhibitions: Exhibitions, friends: Friends, press: Press, program: Program, publications: Visit/Shop/Publications, root: Index, shop: Visit/Shop, visit: Visit, privacy_policy: Privacy-Policy);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.exhibition_page__thumbnail {
  @include ag-book-bq;

  a {
    @include link(no-underline);
    display: block;
  }
}

.exhibition_page__thumbnail__title+.exhibition_page__thumbnail__date_range,
.exhibition_page__thumbnail__subtitle+.exhibition_page__thumbnail__date_range,
.exhibition_page__thumbnail__title+.exhibition_page__thumbnail__location,
.exhibition_page__thumbnail__subtitle+.exhibition_page__thumbnail__location {
  margin-top: .5em;
}

// .exhibition_page__thumbnail__image {
//   aspect-ratio: var(--file-aspect-ratio, 1);
// }

.exhibition_page__thumbnail__exhibition {
  text-transform: lowercase;
}

.exhibition_page__thumbnail__date_range {
  @include semantic-date-time-tag(hide-year-if-current);
  @include semantic-date-time-tag(hide-day-name);
  @include semantic-date-time-tag(hide-year-if-same);
}

.exhibition_page__thumbnail__date_range__from,
.exhibition_page__thumbnail__date_range__until {
  &:before {
    margin-right: 0.3em;
    text-transform: lowercase;
  }
}

.exhibition_page__thumbnail__date_range__from {

  .semantic.from+.date_range_separator,
  .semantic.to {
    display: none;
  }
}

.exhibition_page__thumbnail__date_range__until {

  .semantic.from,
  .semantic.from+.date_range_separator {
    display: none;
  }
}

.exhibition_page__thumbnail[data-hide-dend] {
  .exhibition_page__thumbnail__date_range {

    .date_range_separator,
    .date.to {
      display: none;
    }
  }

  .exhibition_page__thumbnail__date_range__until {
    display: none;
  }
}

.exhibition_page__thumbnail__date_range__from {
  &:before {
    content: attr(data-from);
  }
}

.exhibition_page__thumbnail__date_range__until {
  &:before {
    content: attr(data-until);
  }
}

.exhibition_page__thumbnail__image {
  margin-bottom: .5em;
}

.exhibition_page__thumbnail__image {
  img:not(.lazyloaded) {
    background: color(black);
  }
}

.exhibition_page__thumbnail__image__wrapper {
  @include reset-figure;

  figcaption {
    display: none;
  }
}
