// include in the same element to which you trigger .ImageGallery()

@mixin modulor_image_gallery($slide_selector: 'figure') {
  #{$slide_selector} {
    display: none;

    &.modulor_image_gallery__current_slide,
    &:first-of-type:not(.modulor_image_gallery__inactive_slide) {
      display: block;
    }
  }

  &:not([data-multiple-images]),
  &[data-multiple-images='false'] {
    .modulor_image_gallery__counter {
      display: none;
    }
  }
}
