.text_module__button_to_less {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  font-family: ag-book-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  cursor: pointer;
  outline: 0;
  display: block;
  width: 100%;
  background-color: white;
  text-align: left;
  text-transform: uppercase; }
  .text_module__button_to_less em, .text_module__button_to_less i {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: italic; }
  @media all and (max-width: 739px) {
    .text_module__button_to_less {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .text_module__button_to_less {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .text_module__button_to_less {
      font-size: 2.08rem;
      line-height: 2.4rem;
      letter-spacing: 0; } }
  @media (min-width: 1280px) {
    .text_module__button_to_less {
      font-size: 2.34rem;
      line-height: 2.7rem;
      letter-spacing: 0; } }
  [data-past] .text_module__button_to_less {
    background-color: var(--color-gray, #c7c7c7); }
  .modulor_web_module__is_edit_mode .text_module__button_to_less {
    background-color: white !important; }
  .text_module__button_to_less:hover span {
    text-decoration: none;
    color: black; }
    .text_module__button_to_less:hover span:hover {
      text-decoration: none;
      -webkit-print-color-adjust: exact;
      background-size: 100%;
      background-repeat: repeat-x;
      background-position: 100% 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
  .text_module__button_to_less:before {
    content: '('; }
  .text_module__button_to_less:after {
    content: ')'; }
  @media all and (max-width: 739px) {
    .text_module__button_to_less {
      padding-bottom: 1.125rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .text_module__button_to_less {
      padding-bottom: 1.125rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .text_module__button_to_less {
      padding-bottom: 1.2rem; } }
  @media (min-width: 1280px) {
    .text_module__button_to_less {
      padding-bottom: 1.35rem; } }
