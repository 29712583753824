@charset "UTF-8";
.flickity-page-dots {
  font-family: ag-book-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  position: relative;
  bottom: auto; }
  .flickity-page-dots em, .flickity-page-dots i {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: italic; }
  @media all and (max-width: 739px) {
    .flickity-page-dots {
      font-size: 4.05rem;
      line-height: 4.05rem;
      letter-spacing: -0.025em; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .flickity-page-dots {
      font-size: 4.5rem;
      line-height: 4.5rem;
      letter-spacing: -0.025em; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .flickity-page-dots {
      font-size: 4.8rem;
      line-height: 4.8rem;
      letter-spacing: -0.025em; } }
  @media (min-width: 1280px) {
    .flickity-page-dots {
      font-size: 5.4rem;
      line-height: 5.4rem;
      letter-spacing: -0.025em; } }
  .flickity-page-dots .dot {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    background: none;
    border-radius: 0;
    width: auto;
    height: auto;
    opacity: 1;
    margin: 0;
    margin-left: 0.015em;
    margin-right: 0.015em; }
    .flickity-page-dots .dot em, .flickity-page-dots .dot i {
      font-family: ag-book-pro, sans-serif;
      font-weight: 400;
      font-style: italic; }
    .flickity-page-dots .dot:before {
      content: '•';
      display: inline-block; }
    .flickity-page-dots .dot.is-selected {
      color: white;
      text-stroke-color: black;
      -webkit-text-stroke-color: black;
      text-stroke-width: .03em;
      -webkit-text-stroke-width: .03em; }
      [data-past] .flickity-page-dots .dot.is-selected {
        color: var(--color-gray, #c7c7c7); }
    .flickity-page-dots .dot:first-child:nth-last-child(1) {
      display: none; }
