@mixin list-with-gutter($margin-x, $margin-y, $selector: '> li, > div', $vertical-align: top) {
  margin-left: -($margin-x);
  margin-bottom: -($margin-y);

  #{$selector} {
    margin-bottom: $margin-y;
    padding-left: $margin-x;
    vertical-align: $vertical-align;
  }
}
