.event_list_module__content[data-view-option="related"] .event_list_module__thumbnails {
  list-style: none;
  margin: 0;
  padding: 0; }
  .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails > li {
    margin: 0;
    padding: 0; }
  @media all and (max-width: 739px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails {
      margin-left: -2.25rem;
      margin-bottom: -4.5rem; }
      .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails .event_page__thumbnail {
        margin-bottom: 4.5rem;
        padding-left: 2.25rem;
        vertical-align: top; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails {
      margin-left: -2.25rem;
      margin-bottom: -4.5rem; }
      .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails .event_page__thumbnail {
        margin-bottom: 4.5rem;
        padding-left: 2.25rem;
        vertical-align: top; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails {
      margin-left: -2.4rem;
      margin-bottom: -4.8rem; }
      .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails .event_page__thumbnail {
        margin-bottom: 4.8rem;
        padding-left: 2.4rem;
        vertical-align: top; } }
  @media (min-width: 1280px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails {
      margin-left: -2.7rem;
      margin-bottom: -5.4rem; }
      .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails .event_page__thumbnail {
        margin-bottom: 5.4rem;
        padding-left: 2.7rem;
        vertical-align: top; } }
  @media all and (max-width: 739px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails:not(:last-child) {
      margin-bottom: 2.25rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails:not(:last-child) {
      margin-bottom: 2.25rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails:not(:last-child) {
      margin-bottom: 2.4rem; } }
  @media (min-width: 1280px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails:not(:last-child) {
      margin-bottom: 2.7rem; } }
  @media all and (max-width: 739px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails .event_page__thumbnail {
      /* xs */
      width: 50%; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails .event_page__thumbnail {
      /* s */
      width: 25%; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails .event_page__thumbnail {
      /* m */
      width: 25%; } }
  @media (min-width: 1280px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails .event_page__thumbnail {
      /* l */
      width: 20%; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails .event_page__thumbnail {
      /* m */
      width: 50%; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails .event_page__thumbnail {
      /* s */
      width: 50%; } }
  @media all and (max-width: 739px) {
    .event_list_module__content[data-view-option="related"] .event_list_module__thumbnails .event_page__thumbnail {
      /* xs */
      width: 100%; } }

.event_list_module__content[data-view-option="related"] .event_page__thumbnail__date_range__past {
  color: var(--color-gray, #c7c7c7); }
  [data-past] .event_list_module__content[data-view-option="related"] .event_page__thumbnail__date_range__past {
    color: black; }

.event_list_module__content[data-view-option^="related"] .event_list_module__heading {
  padding: 0;
  margin: 0;
  font-family: ag-book-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: lowercase; }
  .event_list_module__content[data-view-option^="related"] .event_list_module__heading:not(:last-child) {
    margin-bottom: 1em; }
  .event_list_module__content[data-view-option^="related"] .event_list_module__heading em, .event_list_module__content[data-view-option^="related"] .event_list_module__heading i {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: italic; }
  @media all and (max-width: 739px) {
    .event_list_module__content[data-view-option^="related"] .event_list_module__heading {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .event_list_module__content[data-view-option^="related"] .event_list_module__heading {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .event_list_module__content[data-view-option^="related"] .event_list_module__heading {
      font-size: 2.08rem;
      line-height: 2.4rem;
      letter-spacing: 0; } }
  @media (min-width: 1280px) {
    .event_list_module__content[data-view-option^="related"] .event_list_module__heading {
      font-size: 2.34rem;
      line-height: 2.7rem;
      letter-spacing: 0; } }
  @media all and (max-width: 739px) {
    .event_list_module__content[data-view-option^="related"] .event_list_module__heading {
      margin-bottom: 2.25rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .event_list_module__content[data-view-option^="related"] .event_list_module__heading {
      margin-bottom: 2.25rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .event_list_module__content[data-view-option^="related"] .event_list_module__heading {
      margin-bottom: 2.4rem; } }
  @media (min-width: 1280px) {
    .event_list_module__content[data-view-option^="related"] .event_list_module__heading {
      margin-bottom: 2.7rem; } }

.event_list_module__content[data-view-option^="related"] .event_page__thumbnail {
  display: inline-block;
  vertical-align: top; }
  @media all and (max-width: 739px) {
    .event_list_module__content[data-view-option^="related"] .event_page__thumbnail {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .event_list_module__content[data-view-option^="related"] .event_page__thumbnail {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .event_list_module__content[data-view-option^="related"] .event_page__thumbnail {
      font-size: 2.08rem;
      line-height: 2.4rem;
      letter-spacing: 0; } }
  @media (min-width: 1280px) {
    .event_list_module__content[data-view-option^="related"] .event_page__thumbnail {
      font-size: 2.34rem;
      line-height: 2.7rem;
      letter-spacing: 0; } }

@media all and (max-width: 739px) {
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__tags,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__date_range,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__location,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__description,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__more {
    font-size: 1.44rem;
    line-height: 1.76rem;
    letter-spacing: 0; } }

@media (min-width: 740px) and (max-width: 999px) {
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__tags,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__date_range,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__location,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__description,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__more {
    font-size: 1.44rem;
    line-height: 1.76rem;
    letter-spacing: 0; } }

@media (min-width: 1000px) and (max-width: 1279px) {
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__tags,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__date_range,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__location,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__description,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__more {
    font-size: 1.44rem;
    line-height: 1.76rem;
    letter-spacing: 0; } }

@media (min-width: 1280px) {
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__tags,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__date_range,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__location,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__description,
  .event_list_module__content[data-view-option^="related"] .event_page__thumbnail__more {
    font-size: 1.62rem;
    line-height: 1.98rem;
    letter-spacing: 0; } }
