@import 'partials';

$breakpoints: (xs: (query: "all", exclusive_query: "all and (max-width: 739px)", columns: 2), s: (query: "(min-width: 740px)", exclusive_query: "(min-width: 740px) and (max-width: 999px)", columns: 4), m: (query: "(min-width: 1000px)", exclusive_query: "(min-width: 1000px) and (max-width: 1279px)", columns: 4), l: (query: "(min-width: 1280px)", exclusive_query: "(min-width: 1280px)", columns: 5));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Museum, anders_wohnen: Anders-Wohnen, cafe: Museum/Kaiser-Wilhelm-Museum/Cafe, collection: Collection, editions: Visit/Shop/Editions, education: Education, engagement: Engagement, exhibitions: Exhibitions, friends: Friends, press: Press, program: Program, publications: Visit/Shop/Publications, root: Index, shop: Visit/Shop, visit: Visit, privacy_policy: Privacy-Policy);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.press_list_module__tags {
  @include reset-table;

  display: flex;
  flex-direction: column;

  tbody, tr {
    display: flex;
    width: 100%;
  }

  tbody {
    flex-direction: column;
    width: 100%;
  }

  tr {
    flex-direction: row;

    @include breakpoints {
      width: calc(100% + #{$web-module-padding * 2});
      @include list-with-gutter($web-module-padding * 2, 0, 'td');
    }
  }

  tbody {
    flex-direction: column;
    overflow: hidden;
  }

  tr {
    flex-direction: row;

    @include breakpoints {
      width: calc(100% + #{$web-module-padding * 2});
      @include list-with-gutter($web-module-padding * 2, 0, 'td');
    }
  }

  td {
    @include breakpoint-col(width, 1);

    @include breakpoints {
      padding-top: $default-line-height / 2;
      padding-bottom: $default-line-height / 2;

      padding-left: $web-module-padding;
      padding-right: $web-module-padding;
    }

    &.press_page__thumbnail__view_option_for_title {
      @include breakpoint-col(width, 2);
    }
  }

  .press_page__thumbnail {
    border-top: .2rem solid color(black);
    &:last-child {
      border-bottom: .2rem solid color(black);
    }
  }

  .press_list_module__tag {
    &:not(:first-child) {
      .press_list_module__tag__heading_row {
        @include breakpoints { margin-top: $default-line-height * 2; }
      }
    }
  }
}
