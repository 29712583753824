@mixin default-typography($size: default) {
  @include ag-book-bq;
  @include font-size($size);

  h1, h2, h3, h4, h5, h6 {
    @include reset-header;

    @include ag-book-bq;
    @include font-size($size);

    &:not(:first-child) {
      margin-top: 2em;
    }
  }

  h1, h2 {
    text-transform: uppercase;
  }

  a {
    @include link(underline, $size);

    &[href^='mailto:'],
    &[href^='tel:'] {
      @include link(default, $size);
      background: none;
    }
  }

  p { @include reset-p; }

  br + small {
    @include breakpoints {
      margin-top: #{map-deep-get($typography, $size, line-height) - map-deep-get($typography, small, line-height)};
    }
  }

  small {
    @include font-size(small);

    display: block;

    a {
      @include link(underline, small);

      &[href^='mailto:'],
      &[href^='tel:'] {
        @include link(default, small);
        background: none;
      }
    }
  }

  ul {
    @include reset-list;

    li {
      padding-left: 1em;
      &:before {
        content: '– ';
        margin-left: -1em;
      }
    }
  }

  code,
  sup {
    @include font-size(small);
    line-height: 0 !important;
  }

  sup {
    font-size: 0.65em;
  }
}
