@mixin reset-list {
  list-style: none;
  @include reset-margin;
  @include reset-padding;

  > li {
    @include reset-margin;
    @include reset-padding;
  }
}
