// @include semantic-date-time-tag(hide-year-if-current)

@mixin semantic-date-time-tag($type) {
  @if $type == hide-day-name {
    .day.a,
    .day.a + .sep {
      display: none;
    }
  }

  @if $type == hide-day-number {
    .day.d,
    .day.d + .sep {
      display: none;
    }
  }

  @if $type == hide-month {
    .month.b,
    .month.b + .sep {
      display: none !important;
    }
  }

  @if $type == hide-year {
    .year.Y,
    .year.Y + .sep {
      display: none;
    }
  }

  @if $type == hide-date {
    @include semantic-date-time-tag(hide-day-name);
    @include semantic-date-time-tag(hide-day-number);
    @include semantic-date-time-tag(hide-month);
    @include semantic-date-time-tag(hide-year);
  }

  // conditional

  @if $type == hide-year-if-current {
    .current_year:not(.from):not(.to) {
      .en {
        .day.d + .sep,
        .year.Y {
          display: none;
        }
      }
      .de {
        &.from {
          .month.b + .sep {
            display: inline-block;
          }
        }
        .year.Y {
          display: none;
        }
      }
    }
    .en.current_year:not(.from):not(.to) {
      .day.d + .sep,
      .year.Y {
        display: none;
      }
    }
    .de.current_year:not(.from):not(.to) {
      .year.Y {
        display: none;
      }
    }
  }

  @if $type == hide-year-if-same {
    .same_year:not(.same_day) {
      .en.from {
        .day.d + .sep,
        .year {
          display: none;
        }
      }
      .de.from {
        .month.b + .sep {
          display: inline-block;
        }
        .year {
          display: none;
        }
      }
    }
  }

  @if $type == hide-minutes-if-whole-hour {
    .whole_hour.de {
      .hours.H + .sep,
      .minutes.M {
        display: none;
      }
    }

    .whole_hour.en {
      .hours.I + .sep,
      .minutes.M {
        display: none;
      }
    }
  }

  @if $type == hide-meridian-if-same {
    .same_meridian {
      .en.from {
        .minutes + .sep,
        .ampm {
          display: none;
        }
      }
      .de.from {
        .minutes.M + .str {
          display: none;
        }
      }
    }
  }

  @if $type == hide-to-date-if-same-day {
    .same_day {
      .de.from {
        .minutes.M + .str {
          display: none;
        }
      }

      &.same_meridian {
        .en.from {
          .minutes.M + .sep,
          .ampm {
            display: none;
          }
        }
      }

      .to {
        @include semantic-date-time-tag(hide-date);
      }
    }
  }
}
