@import 'partials';

$breakpoints: (xs: (query: "all", exclusive_query: "all and (max-width: 739px)", columns: 2), s: (query: "(min-width: 740px)", exclusive_query: "(min-width: 740px) and (max-width: 999px)", columns: 4), m: (query: "(min-width: 1000px)", exclusive_query: "(min-width: 1000px) and (max-width: 1279px)", columns: 4), l: (query: "(min-width: 1280px)", exclusive_query: "(min-width: 1280px)", columns: 5));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Museum, anders_wohnen: Anders-Wohnen, cafe: Museum/Kaiser-Wilhelm-Museum/Cafe, collection: Collection, editions: Visit/Shop/Editions, education: Education, engagement: Engagement, exhibitions: Exhibitions, friends: Friends, press: Press, program: Program, publications: Visit/Shop/Publications, root: Index, shop: Visit/Shop, visit: Visit, privacy_policy: Privacy-Policy);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
body[data-path^="#{path(friends)}"] {
  .expandable_text_module__expandable_text {
    color: color(friends);
  }

  .expandable_text_module__expandable_text__tab {
    button {
      color: color(friends);
      &:before {
        background: svg-load('arrow.svg') no-repeat;
      }
    }
  }

  .expandable_text_module__expandable_text {
    border-top: .2rem solid color(friends);
    &:last-child { border-bottom: .2rem solid color(friends); }
  }

  .expandable_text_module__expandable_text__body {
    color: color(friends);

    a {
      @include link(underline, default, color(friends));

      &[href^='mailto:'],
      &[href^='tel:'] {
        @include link(default, default, color(friends));
        background: none;
      }
    }

    small {
      a {
        @include link(underline, small, color(friends));

        &[href^='mailto:'],
        &[href^='tel:'] {
          @include link(default, small, color(friends));
          background: none;
        }
      }
    }
  }
}
