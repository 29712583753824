@import 'partials';

$breakpoints: (xs: (query: "all", exclusive_query: "all and (max-width: 739px)", columns: 2), s: (query: "(min-width: 740px)", exclusive_query: "(min-width: 740px) and (max-width: 999px)", columns: 4), m: (query: "(min-width: 1000px)", exclusive_query: "(min-width: 1000px) and (max-width: 1279px)", columns: 4), l: (query: "(min-width: 1280px)", exclusive_query: "(min-width: 1280px)", columns: 5));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Museum, anders_wohnen: Anders-Wohnen, cafe: Museum/Kaiser-Wilhelm-Museum/Cafe, collection: Collection, editions: Visit/Shop/Editions, education: Education, engagement: Engagement, exhibitions: Exhibitions, friends: Friends, press: Press, program: Program, publications: Visit/Shop/Publications, root: Index, shop: Visit/Shop, visit: Visit, privacy_policy: Privacy-Policy);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.event_list_module__content[data-view-option="on_view"][data-display-option="carousel"] {
  .event_list_module__heading {
    @include reset-header;

    @include ag-book-bq;
    @include font-size(large);

    text-transform: lowercase;

    &:not(:last-child) {
      @include breakpoints {
        margin-bottom: $default-line-height;
      }
    }
  }

  .event_list_module__section {
    @include breakpoints {
      @include inline-block-list($web-module-padding * 2, 0, '.event_list_module__section__day');
    }
  }

  .event_list_module__section__day {
    display: inline-block;
    vertical-align: top;

    @include breakpoints {
      width: calc(#{fluid-col(1, $columns)} + #{$web-module-padding * 2});
      padding-right: $web-module-padding;
      padding-left: $web-module-padding;
    }
  }

  .event_list_module__section__group_key__day {
    .day.a {
      display: block;
    }

    .day.a+.sep {
      display: none;
    }

    .date_queries {
      display: none;
    }

    @include breakpoints {
      margin-bottom: $default-line-height;
    }
  }

  .event_list_module__buttons__nav {
    display: flex;
    justify-content: flex-end;
  }


  .event_list_module__thumbnails {
    display: inline-block;

    .event_page__thumbnail {
      display: block;
      width: 100%;
    }

    .event_page__thumbnail__title {
      display: inline;
      @include underline-for(default);
    }
  }
}
