@charset "UTF-8";
.announcement_module__content {
  font-family: ag-book-pro, sans-serif;
  font-weight: 400;
  font-style: normal; }
  .announcement_module__content em, .announcement_module__content i {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: italic; }
  @media all and (max-width: 739px) {
    .announcement_module__content {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .announcement_module__content {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .announcement_module__content {
      font-size: 2.08rem;
      line-height: 2.4rem;
      letter-spacing: 0; } }
  @media (min-width: 1280px) {
    .announcement_module__content {
      font-size: 2.34rem;
      line-height: 2.7rem;
      letter-spacing: 0; } }

.announcement_module__heading {
  padding: 0;
  margin: 0;
  font-family: ag-book-pro, sans-serif;
  font-weight: 400;
  font-style: normal; }
  .announcement_module__heading:not(:last-child) {
    margin-bottom: 1em; }
  .announcement_module__heading em, .announcement_module__heading i {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: italic; }
  @media all and (max-width: 739px) {
    .announcement_module__heading {
      font-size: 4.05rem;
      line-height: 4.05rem;
      letter-spacing: -0.025em; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .announcement_module__heading {
      font-size: 4.5rem;
      line-height: 4.5rem;
      letter-spacing: -0.025em; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .announcement_module__heading {
      font-size: 4.8rem;
      line-height: 4.8rem;
      letter-spacing: -0.025em; } }
  @media (min-width: 1280px) {
    .announcement_module__heading {
      font-size: 5.4rem;
      line-height: 5.4rem;
      letter-spacing: -0.025em; } }
  @media all and (max-width: 739px) {
    .announcement_module__heading:not(:last-child) {
      margin-bottom: 2.25rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .announcement_module__heading:not(:last-child) {
      margin-bottom: 2.25rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .announcement_module__heading:not(:last-child) {
      margin-bottom: 2.4rem; } }
  @media (min-width: 1280px) {
    .announcement_module__heading:not(:last-child) {
      margin-bottom: 2.7rem; } }

.announcement_module__items {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  min-height: 100%; }
  @media all and (max-width: 739px) {
    .announcement_module__items {
      margin-right: -1.125rem;
      margin-left: -1.125rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .announcement_module__items {
      margin-right: -1.125rem;
      margin-left: -1.125rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .announcement_module__items {
      margin-right: -1.2rem;
      margin-left: -1.2rem; } }
  @media (min-width: 1280px) {
    .announcement_module__items {
      margin-right: -1.35rem;
      margin-left: -1.35rem; } }

.announcement_module__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: .5rem;
  min-height: 100%; }
  @media all and (max-width: 739px) {
    .announcement_module__item {
      width: calc(100%);
      padding-right: 1.125rem;
      padding-left: 1.125rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .announcement_module__item {
      width: calc(50%);
      padding-right: 1.125rem;
      padding-left: 1.125rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .announcement_module__item {
      width: calc(50%);
      padding-right: 1.2rem;
      padding-left: 1.2rem; } }
  @media (min-width: 1280px) {
    .announcement_module__item {
      width: calc(40%);
      padding-right: 1.35rem;
      padding-left: 1.35rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .announcement_module__item {
      width: 100%; } }
  @media all and (max-width: 739px) {
    .announcement_module__item {
      width: 100%; } }

.announcement_module[data-item-count="1"] .announcement_module__items {
  margin-left: 0;
  margin-right: 0; }

.announcement_module[data-item-count="1"] .announcement_module__item {
  padding-left: 0;
  padding-right: 0;
  width: 100%; }

.announcement_module__attachment_images {
  list-style: none;
  margin: 0;
  padding: 0;
  aspect-ratio: var(--min-aspect-ratio, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; }
  .announcement_module__attachment_images > li {
    margin: 0;
    padding: 0; }

@media all and (max-width: 739px) {
  a + .announcement_module__heading {
    margin-top: 1.125rem; } }

@media (min-width: 740px) and (max-width: 999px) {
  a + .announcement_module__heading {
    margin-top: 1.125rem; } }

@media (min-width: 1000px) and (max-width: 1279px) {
  a + .announcement_module__heading {
    margin-top: 1.2rem; } }

@media (min-width: 1280px) {
  a + .announcement_module__heading {
    margin-top: 1.35rem; } }

.announcement_module__attachment_image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  width: 100%; }

.announcement_module__attachment_image__figure {
  margin: 0;
  padding: 0; }

.announcement_module__content .flickity-page-dots {
  font-family: ag-book-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 0;
  display: flex;
  gap: .25em;
  justify-content: center; }
  .announcement_module__content .flickity-page-dots em, .announcement_module__content .flickity-page-dots i {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: italic; }
  @media all and (max-width: 739px) {
    .announcement_module__content .flickity-page-dots {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .announcement_module__content .flickity-page-dots {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .announcement_module__content .flickity-page-dots {
      font-size: 2.08rem;
      line-height: 2.4rem;
      letter-spacing: 0; } }
  @media (min-width: 1280px) {
    .announcement_module__content .flickity-page-dots {
      font-size: 2.34rem;
      line-height: 2.7rem;
      letter-spacing: 0; } }

.announcement_module__content .flickity-page-dots .dot {
  color: var(--color-gray, #c7c7c7);
  text-stroke: none;
  -webkit-text-stroke: none;
  opacity: initial;
  margin: 0;
  padding: 0; }
  .announcement_module__content .flickity-page-dots .dot:hover:before {
    color: black; }
  .announcement_module__content .flickity-page-dots .dot:before {
    content: " ";
    width: .5em;
    height: .5em;
    background-color: currentColor;
    border-radius: 100%; }
  .announcement_module__content .flickity-page-dots .dot.is-selected {
    color: black; }

.announcement_module__body {
  font-family: ag-book-pro, sans-serif;
  font-weight: 400;
  font-style: normal; }
  .announcement_module__body em, .announcement_module__body i {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: italic; }
  @media all and (max-width: 739px) {
    .announcement_module__body {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .announcement_module__body {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .announcement_module__body {
      font-size: 2.08rem;
      line-height: 2.4rem;
      letter-spacing: 0; } }
  @media (min-width: 1280px) {
    .announcement_module__body {
      font-size: 2.34rem;
      line-height: 2.7rem;
      letter-spacing: 0; } }
  .announcement_module__body h1, .announcement_module__body h2, .announcement_module__body h3, .announcement_module__body h4, .announcement_module__body h5, .announcement_module__body h6 {
    padding: 0;
    margin: 0;
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: normal; }
    .announcement_module__body h1:not(:last-child), .announcement_module__body h2:not(:last-child), .announcement_module__body h3:not(:last-child), .announcement_module__body h4:not(:last-child), .announcement_module__body h5:not(:last-child), .announcement_module__body h6:not(:last-child) {
      margin-bottom: 1em; }
    .announcement_module__body h1 em, .announcement_module__body h1 i, .announcement_module__body h2 em, .announcement_module__body h2 i, .announcement_module__body h3 em, .announcement_module__body h3 i, .announcement_module__body h4 em, .announcement_module__body h4 i, .announcement_module__body h5 em, .announcement_module__body h5 i, .announcement_module__body h6 em, .announcement_module__body h6 i {
      font-family: ag-book-pro, sans-serif;
      font-weight: 400;
      font-style: italic; }
    @media all and (max-width: 739px) {
      .announcement_module__body h1, .announcement_module__body h2, .announcement_module__body h3, .announcement_module__body h4, .announcement_module__body h5, .announcement_module__body h6 {
        font-size: 1.95rem;
        line-height: 2.25rem;
        letter-spacing: 0; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .announcement_module__body h1, .announcement_module__body h2, .announcement_module__body h3, .announcement_module__body h4, .announcement_module__body h5, .announcement_module__body h6 {
        font-size: 1.95rem;
        line-height: 2.25rem;
        letter-spacing: 0; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .announcement_module__body h1, .announcement_module__body h2, .announcement_module__body h3, .announcement_module__body h4, .announcement_module__body h5, .announcement_module__body h6 {
        font-size: 2.08rem;
        line-height: 2.4rem;
        letter-spacing: 0; } }
    @media (min-width: 1280px) {
      .announcement_module__body h1, .announcement_module__body h2, .announcement_module__body h3, .announcement_module__body h4, .announcement_module__body h5, .announcement_module__body h6 {
        font-size: 2.34rem;
        line-height: 2.7rem;
        letter-spacing: 0; } }
    .announcement_module__body h1:not(:first-child), .announcement_module__body h2:not(:first-child), .announcement_module__body h3:not(:first-child), .announcement_module__body h4:not(:first-child), .announcement_module__body h5:not(:first-child), .announcement_module__body h6:not(:first-child) {
      margin-top: 2em; }
  .announcement_module__body h1, .announcement_module__body h2 {
    text-transform: uppercase; }
  .announcement_module__body a {
    text-decoration: none;
    color: black;
    text-decoration: none;
    -webkit-print-color-adjust: exact;
    background-size: 100%;
    background-repeat: repeat-x;
    background-position: 100% 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
    .announcement_module__body a[href^='mailto:'], .announcement_module__body a[href^='tel:'] {
      text-decoration: none;
      color: black;
      background: none; }
      .announcement_module__body a[href^='mailto:']:hover, .announcement_module__body a[href^='tel:']:hover {
        text-decoration: none;
        -webkit-print-color-adjust: exact;
        background-size: 100%;
        background-repeat: repeat-x;
        background-position: 100% 0;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
  .announcement_module__body p {
    margin: 0;
    padding: 0; }
    .announcement_module__body p:not(:last-child) {
      margin-bottom: 1em; }
  @media all and (max-width: 739px) {
    .announcement_module__body br + small {
      margin-top: 0.49rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .announcement_module__body br + small {
      margin-top: 0.49rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .announcement_module__body br + small {
      margin-top: 0.64rem; } }
  @media (min-width: 1280px) {
    .announcement_module__body br + small {
      margin-top: 0.72rem; } }
  .announcement_module__body small {
    display: block; }
    @media all and (max-width: 739px) {
      .announcement_module__body small {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .announcement_module__body small {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .announcement_module__body small {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1280px) {
      .announcement_module__body small {
        font-size: 1.62rem;
        line-height: 1.98rem;
        letter-spacing: 0; } }
    .announcement_module__body small a {
      text-decoration: none;
      color: black;
      text-decoration: none;
      -webkit-print-color-adjust: exact;
      background-size: 100%;
      background-repeat: repeat-x;
      background-position: 100% 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
      .announcement_module__body small a[href^='mailto:'], .announcement_module__body small a[href^='tel:'] {
        text-decoration: none;
        color: black;
        background: none; }
        .announcement_module__body small a[href^='mailto:']:hover, .announcement_module__body small a[href^='tel:']:hover {
          text-decoration: none;
          -webkit-print-color-adjust: exact;
          background-size: 100%;
          background-repeat: repeat-x;
          background-position: 100% 0;
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
  .announcement_module__body ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .announcement_module__body ul > li {
      margin: 0;
      padding: 0; }
    .announcement_module__body ul li {
      padding-left: 1em; }
      .announcement_module__body ul li:before {
        content: '– ';
        margin-left: -1em; }
  .announcement_module__body code,
  .announcement_module__body sup {
    line-height: 0 !important; }
    @media all and (max-width: 739px) {
      .announcement_module__body code,
      .announcement_module__body sup {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .announcement_module__body code,
      .announcement_module__body sup {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .announcement_module__body code,
      .announcement_module__body sup {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1280px) {
      .announcement_module__body code,
      .announcement_module__body sup {
        font-size: 1.62rem;
        line-height: 1.98rem;
        letter-spacing: 0; } }
  .announcement_module__body sup {
    font-size: 0.65em; }

.announcement_module__related_pages {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 2.7rem; }
  .announcement_module__related_pages > li {
    margin: 0;
    padding: 0; }
  .announcement_module__related_pages a {
    text-decoration: none;
    color: black;
    text-decoration: none;
    -webkit-print-color-adjust: exact;
    background-size: 100%;
    background-repeat: repeat-x;
    background-position: 100% 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
