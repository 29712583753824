@media all and (max-width: 739px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 1.125rem; } }

@media (min-width: 740px) and (max-width: 999px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 1.125rem; } }

@media (min-width: 1000px) and (max-width: 1279px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 1.2rem; } }

@media (min-width: 1280px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 1.35rem; } }

div:not(.modulor_web_module__is_edit_mode) .analytics_module, div:not(.modulor_web_module__is_edit_mode)
.column_module, div:not(.modulor_web_module__is_edit_mode)
.yield_module {
  padding: 0; }

div:not(.modulor_web_module__is_edit_mode) .flash_module {
  padding-top: 0;
  padding-bottom: 0; }
