@mixin underline($offset, $weight, $color) {
  text-decoration: none;
  -webkit-print-color-adjust: exact; // force browser to print gradients
  background-size: 100%;
  background-repeat: repeat-x;
  background-position: 100% 0;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0) $offset,
    $color $offset,
    $color ($offset + $weight),
    rgba(0, 0, 0, 0) ($offset + $weight)
  );
}
