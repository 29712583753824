@mixin reset-button {
  -webkit-appearance: none; // ios
  -webkit-border-radius:0; // ios
  background-color: transparent;
  border-radius: 0; // ios
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
