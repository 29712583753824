@import 'partials';

$breakpoints: (xs: (query: "all", exclusive_query: "all and (max-width: 739px)", columns: 2), s: (query: "(min-width: 740px)", exclusive_query: "(min-width: 740px) and (max-width: 999px)", columns: 4), m: (query: "(min-width: 1000px)", exclusive_query: "(min-width: 1000px) and (max-width: 1279px)", columns: 4), l: (query: "(min-width: 1280px)", exclusive_query: "(min-width: 1280px)", columns: 5));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Museum, anders_wohnen: Anders-Wohnen, cafe: Museum/Kaiser-Wilhelm-Museum/Cafe, collection: Collection, editions: Visit/Shop/Editions, education: Education, engagement: Engagement, exhibitions: Exhibitions, friends: Friends, press: Press, program: Program, publications: Visit/Shop/Publications, root: Index, shop: Visit/Shop, visit: Visit, privacy_policy: Privacy-Policy);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.article_header_module__heading {
  @include ag-book-bq;
  @include font-size(default);

  margin: 0;
  padding: 0;
  list-style: none;
  min-height: 6em;

  display: grid;

  @include breakpoints {
    grid-template-columns: repeat(#{$columns}, 1fr);
  }

  @include breakpoints(m s) { grid-template-columns: 12.5% 35% 35% 8.75% 8.75%; }
  @include breakpoints(xs) {
    grid-template-columns: 20% 35% 35% 10%;
    .article_header_module__lang_switch { text-align: right; }
  }

  a {
    color: color(black);
    text-decoration: none;
  }

  &.clone {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    min-height: initial;

    @include breakpoints {
      padding: $web-module-padding;
      left: $web-module-padding * 3;
    }

    &[data-state="bg"] {
      background: inherit;

      .article_header_module__catalogue__title {
        display: none;
      }
      .article_header_module__catalogue__article_title {
        display: block;
      }
    }

    .user_signed_in & {
      position: relative;
      left: initial;
      top: initial;
    }
  }
}
