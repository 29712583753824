@import 'partials';

$breakpoints: (xs: (query: "all", exclusive_query: "all and (max-width: 739px)", columns: 2), s: (query: "(min-width: 740px)", exclusive_query: "(min-width: 740px) and (max-width: 999px)", columns: 4), m: (query: "(min-width: 1000px)", exclusive_query: "(min-width: 1000px) and (max-width: 1279px)", columns: 4), l: (query: "(min-width: 1280px)", exclusive_query: "(min-width: 1280px)", columns: 5));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Museum, anders_wohnen: Anders-Wohnen, cafe: Museum/Kaiser-Wilhelm-Museum/Cafe, collection: Collection, editions: Visit/Shop/Editions, education: Education, engagement: Engagement, exhibitions: Exhibitions, friends: Friends, press: Press, program: Program, publications: Visit/Shop/Publications, root: Index, shop: Visit/Shop, visit: Visit, privacy_policy: Privacy-Policy);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.press_header_module__properties {
  @include reset-table;

  @include ag-book-bq;
  @include font-size(default);

  display: flex;

  tbody, tr {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  tr {
    @include breakpoints {
      width: calc(100% + #{$web-module-padding * 2});
      @include list-with-gutter($web-module-padding * 2, 0, 'td');
    }
  }

  border-top: .2rem solid color(black);

  .press_header_module__tags {
    ul { @include reset-list; }
    text-transform: lowercase;
    flex: 1 1;
  }

  .press_header_module__dposted {
    @include breakpoint-col(width, 1);
    @include breakpoint-col(width, 2, s xs);

    @include semantic-date-time-tag(hide-day-name);
    @include semantic-date-time-tag(hide-year-if-current);
  }

  td {
    @include breakpoints {
      padding-top: $default-line-height / 2;

      padding-left: $web-module-padding;
      padding-right: $web-module-padding;
    }
  }
}
