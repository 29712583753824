@media all and (max-width: 739px) {
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail {
    font-size: 1.95rem;
    line-height: 2.25rem;
    letter-spacing: 0; } }

@media (min-width: 740px) and (max-width: 999px) {
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail {
    font-size: 1.95rem;
    line-height: 2.25rem;
    letter-spacing: 0; } }

@media (min-width: 1000px) and (max-width: 1279px) {
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail {
    font-size: 2.08rem;
    line-height: 2.4rem;
    letter-spacing: 0; } }

@media (min-width: 1280px) {
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail {
    font-size: 2.34rem;
    line-height: 2.7rem;
    letter-spacing: 0; } }

.event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__tags {
  display: none; }

@media all and (max-width: 739px) {
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__date_range,
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__description,
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__more,
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__location {
    font-size: 1.44rem;
    line-height: 1.76rem;
    letter-spacing: 0; } }

@media (min-width: 740px) and (max-width: 999px) {
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__date_range,
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__description,
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__more,
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__location {
    font-size: 1.44rem;
    line-height: 1.76rem;
    letter-spacing: 0; } }

@media (min-width: 1000px) and (max-width: 1279px) {
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__date_range,
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__description,
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__more,
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__location {
    font-size: 1.44rem;
    line-height: 1.76rem;
    letter-spacing: 0; } }

@media (min-width: 1280px) {
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__date_range,
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__description,
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__more,
  .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__location {
    font-size: 1.62rem;
    line-height: 1.98rem;
    letter-spacing: 0; } }

.event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__date_range__past {
  color: var(--color-gray, #c7c7c7); }
  [data-past] .event_list_module__content[data-view-option="with_tags"] .event_page__thumbnail__date_range__past {
    color: black; }
