@import 'partials';

$breakpoints: (xs: (query: "all", exclusive_query: "all and (max-width: 739px)", columns: 2), s: (query: "(min-width: 740px)", exclusive_query: "(min-width: 740px) and (max-width: 999px)", columns: 4), m: (query: "(min-width: 1000px)", exclusive_query: "(min-width: 1000px) and (max-width: 1279px)", columns: 4), l: (query: "(min-width: 1280px)", exclusive_query: "(min-width: 1280px)", columns: 5));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Museum, anders_wohnen: Anders-Wohnen, cafe: Museum/Kaiser-Wilhelm-Museum/Cafe, collection: Collection, editions: Visit/Shop/Editions, education: Education, engagement: Engagement, exhibitions: Exhibitions, friends: Friends, press: Press, program: Program, publications: Visit/Shop/Publications, root: Index, shop: Visit/Shop, visit: Visit, privacy_policy: Privacy-Policy);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.gdpr__container {
  @include ag-book-bq;
  @include font-size(small);

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  @include breakpoints {
    padding: $web-module-padding;
    padding-top: 0;
    padding-bottom: $web-module-padding;
    width: 100%;
  }

  @include breakpoints(xs) {
    padding-left: $web-module-padding;
    padding-right: $web-module-padding;
  }

  background-color: color(white);
  padding-top: 1rem;

  z-index: 999;
}

.gdpr__content {
  display: grid;
  grid-template-columns: 1fr auto;

  border-top: .2rem solid color(black);

  @include breakpoints {
    grid-column-gap: #{$web-module-padding * 2};
    padding-top: $web-module-padding;
  }
}

.gdpr__consent {
  color: color(gray);
}

.gdpr__link {
  @include underline-for(default, color(gray)) text-decoration: none;
  color: color(gray);
}

.gdpr__buttons {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: start;

  @include breakpoints {
    grid-column-gap: $web-module-padding;
  }
}

.gdpr__button {
  @include ag-book-bq;
  @include font-size(small);
  @include reset-button;

  text-transform: uppercase;
  white-space: nowrap;
}

.gdpr__button[data-value="disagree"] {
  color: color(black);
}

.gdpr__button[data-value="agree"] {
  color: color(black);
}
