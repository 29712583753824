.announcement_module__buttons__nav {
  font-family: ag-book-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  position: absolute; }
  .announcement_module__buttons__nav em, .announcement_module__buttons__nav i {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: italic; }
  @media all and (max-width: 739px) {
    .announcement_module__buttons__nav {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .announcement_module__buttons__nav {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .announcement_module__buttons__nav {
      font-size: 2.08rem;
      line-height: 2.4rem;
      letter-spacing: 0; } }
  @media (min-width: 1280px) {
    .announcement_module__buttons__nav {
      font-size: 2.34rem;
      line-height: 2.7rem;
      letter-spacing: 0; } }
  @media all and (max-width: 739px) {
    .announcement_module__buttons__nav {
      right: 1.125rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .announcement_module__buttons__nav {
      right: 1.125rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .announcement_module__buttons__nav {
      right: 1.2rem; } }
  @media (min-width: 1280px) {
    .announcement_module__buttons__nav {
      right: 1.35rem; } }

.announcement_module__buttons__button {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 4em;
  height: 4em;
  outline: 0; }
  .announcement_module__buttons__button:not(:last-child) {
    margin-right: 1em; }
  .announcement_module__buttons__button svg {
    overflow: visible;
    width: 90%; }
    .announcement_module__buttons__button svg line,
    .announcement_module__buttons__button svg polygon,
    .announcement_module__buttons__button svg polyline {
      fill: none;
      stroke-width: 1.0rem;
      stroke: black; }
      @media (min-width: 1000px) and (max-width: 1279px) {
        .announcement_module__buttons__button svg line,
        .announcement_module__buttons__button svg polygon,
        .announcement_module__buttons__button svg polyline {
          stroke-width: 0.75rem; } }
      @media (min-width: 740px) and (max-width: 999px) {
        .announcement_module__buttons__button svg line,
        .announcement_module__buttons__button svg polygon,
        .announcement_module__buttons__button svg polyline {
          stroke-width: 0.75rem; } }
      @media all and (max-width: 739px) {
        .announcement_module__buttons__button svg line,
        .announcement_module__buttons__button svg polygon,
        .announcement_module__buttons__button svg polyline {
          stroke-width: 0.75rem; } }
  .announcement_module__buttons__button.announcement_module__buttons__button__inactive line,
  .announcement_module__buttons__button.announcement_module__buttons__button__inactive polygon,
  .announcement_module__buttons__button.announcement_module__buttons__button__inactive polyline {
    stroke: var(--color-gray, #c7c7c7); }
    [data-past] .announcement_module__buttons__button.announcement_module__buttons__button__inactive line, [data-past]
    .announcement_module__buttons__button.announcement_module__buttons__button__inactive polygon, [data-past]
    .announcement_module__buttons__button.announcement_module__buttons__button__inactive polyline {
      stroke: var(--color-gray, #959595); }
