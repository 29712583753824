@import 'partials';

$breakpoints: (xs: (query: "all", exclusive_query: "all and (max-width: 739px)", columns: 2), s: (query: "(min-width: 740px)", exclusive_query: "(min-width: 740px) and (max-width: 999px)", columns: 4), m: (query: "(min-width: 1000px)", exclusive_query: "(min-width: 1000px) and (max-width: 1279px)", columns: 4), l: (query: "(min-width: 1280px)", exclusive_query: "(min-width: 1280px)", columns: 5));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Museum, anders_wohnen: Anders-Wohnen, cafe: Museum/Kaiser-Wilhelm-Museum/Cafe, collection: Collection, editions: Visit/Shop/Editions, education: Education, engagement: Engagement, exhibitions: Exhibitions, friends: Friends, press: Press, program: Program, publications: Visit/Shop/Publications, root: Index, shop: Visit/Shop, visit: Visit, privacy_policy: Privacy-Policy);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.overlay[data-new] {
  --transition-duration: 350ms;

  will-change: transform;
}

.overlay[data-new][data-state-option="onclick"] {
  .overlay__close {
    display: none;
  }
}

.overlay[data-new][data-state-option="permanent"] {
  .overlay__close {
    @include ag-book-bq;
    @include font-size(default);

    @include reset-button;

    position: fixed;

    @include breakpoints {
      top: $web-module-padding;
      right: $web-module-padding;
    }

    z-index: z-index(lightbox, close);

    svg {
      width: 4rem;
      height: 4rem;

      @include breakpoints(xs) {
        width: 3rem;
        height: 3rem;
      }

      overflow: visible;

      line {
        stroke-width: 1.0rem;
        stroke: color(black);

        @include breakpoints(m) {
          stroke-width: 0.75rem;
        }

        @include breakpoints(s) {
          stroke-width: 0.75rem;
        }

        @include breakpoints(xs) {
          stroke-width: 0.75rem;
        }
      }
    }
  }
}

.overlay[data-new][data-view-option="mask"] {
  .overlay__svg {
    width: 0;
    height: 0;
    display: none;
  }

  .overlay__group {
    display: none;
  }

  .overlay__content {
    display: none;
  }

  .overlay__img {
    object-fit: cover;
    object-position: center;

    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .overlay__path {}

  .overlay__rect {
    // transition-property: transform;
    // transition-duration: var(--transition-duration);
    // transition-timing-function: ease-in;
  }

  &[data-expanded] {
    position: fixed;
    width: 100%;
    height: 100%;
    cursor: pointer;

    .overlay__content {
      display: initial;
    }

    .overlay__mask {
      position: absolute;
      width: 100%;
      height: 100%;

      top: 0;
      left: 0;

      clip-path: url(#krefeld--overlay--clip-path);

      overflow: hidden;
    }

    .overlay__mask__yield {
      pointer-events: none;
    }

    .overlay__svg__mask {
      display: initial;
    }

    .overlay__rect {}

    &[data-state-option="permanent"] {
      .overlay__rect {
        display: none;
      }
    }

    .overlay__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.overlay[data-new][data-expanded][data-view-option="typography"] {
  position: fixed;
  width: 100%;
  height: 100%;

  .overlay__img {}

  .overlay__content {
    display: none;
  }

  .overlay__svg__mask {
    display: none;
  }

  .overlay__svg__typography {
    transition-property: clip-path;
    transition-duration: var(--transition-duration);
    clip-path: inset(0 0 100% 0);

    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    display: block;
    overflow: visible;
  }

  &[data-state-option="permanent"] {
    .overlay__svg__typography {
      clip-path: inset(0 0 0 0);
    }
  }
}
