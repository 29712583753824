@mixin modulor-tab($type: 'default') {
  border-bottom: modulor-border();
  color: modulor-color(blue);
  cursor: pointer;
  display: inline-block;
  margin-left: -.1rem;
  padding: .5em;
  padding-left: 1em;
  padding-right: 1em;
  user-select: none;

  @if $type == 'current' {
    @include modulor-border(tab);
    color: modulor-color(black);
    cursor: default;
    margin-left: 0;
    margin-right: 0;

    a {
      color: modulor-color(black);
      cursor: default;
      &:hover {
        color: modulor-color(black);
      }
    }
  }
}
