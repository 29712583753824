@mixin link($type: default, $size: default, $color: color(black)) {
  text-decoration: none;

  @if $type ==default {
    color: $color;

    &:hover {
      @include underline-for($size, $color);
    }
  }

  @if $type ==underline {
    color: $color;
    @include underline-for($size, $color);
  }

  @if $type ==gray {
    color: var(--color-gray, color(gray));

    &:hover {
      color: black;
    }
  }

  @if $type ==white {
    color: color(white);

    &:hover {
      color: black;
    }
  }

  @if $type ==no-underline {
    color: $color;
  }
}
