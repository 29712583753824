@charset "UTF-8";
.visit_module__column {
  font-family: ag-book-pro, sans-serif;
  font-weight: 400;
  font-style: normal; }
  .visit_module__column em, .visit_module__column i {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: italic; }
  @media all and (max-width: 739px) {
    .visit_module__column {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .visit_module__column {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .visit_module__column {
      font-size: 2.08rem;
      line-height: 2.4rem;
      letter-spacing: 0; } }
  @media (min-width: 1280px) {
    .visit_module__column {
      font-size: 2.34rem;
      line-height: 2.7rem;
      letter-spacing: 0; } }
  .visit_module__column h1, .visit_module__column h2, .visit_module__column h3, .visit_module__column h4, .visit_module__column h5, .visit_module__column h6 {
    padding: 0;
    margin: 0;
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: normal; }
    .visit_module__column h1:not(:last-child), .visit_module__column h2:not(:last-child), .visit_module__column h3:not(:last-child), .visit_module__column h4:not(:last-child), .visit_module__column h5:not(:last-child), .visit_module__column h6:not(:last-child) {
      margin-bottom: 1em; }
    .visit_module__column h1 em, .visit_module__column h1 i, .visit_module__column h2 em, .visit_module__column h2 i, .visit_module__column h3 em, .visit_module__column h3 i, .visit_module__column h4 em, .visit_module__column h4 i, .visit_module__column h5 em, .visit_module__column h5 i, .visit_module__column h6 em, .visit_module__column h6 i {
      font-family: ag-book-pro, sans-serif;
      font-weight: 400;
      font-style: italic; }
    @media all and (max-width: 739px) {
      .visit_module__column h1, .visit_module__column h2, .visit_module__column h3, .visit_module__column h4, .visit_module__column h5, .visit_module__column h6 {
        font-size: 1.95rem;
        line-height: 2.25rem;
        letter-spacing: 0; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .visit_module__column h1, .visit_module__column h2, .visit_module__column h3, .visit_module__column h4, .visit_module__column h5, .visit_module__column h6 {
        font-size: 1.95rem;
        line-height: 2.25rem;
        letter-spacing: 0; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .visit_module__column h1, .visit_module__column h2, .visit_module__column h3, .visit_module__column h4, .visit_module__column h5, .visit_module__column h6 {
        font-size: 2.08rem;
        line-height: 2.4rem;
        letter-spacing: 0; } }
    @media (min-width: 1280px) {
      .visit_module__column h1, .visit_module__column h2, .visit_module__column h3, .visit_module__column h4, .visit_module__column h5, .visit_module__column h6 {
        font-size: 2.34rem;
        line-height: 2.7rem;
        letter-spacing: 0; } }
    .visit_module__column h1:not(:first-child), .visit_module__column h2:not(:first-child), .visit_module__column h3:not(:first-child), .visit_module__column h4:not(:first-child), .visit_module__column h5:not(:first-child), .visit_module__column h6:not(:first-child) {
      margin-top: 2em; }
  .visit_module__column h1, .visit_module__column h2 {
    text-transform: uppercase; }
  .visit_module__column a {
    text-decoration: none;
    color: black;
    text-decoration: none;
    -webkit-print-color-adjust: exact;
    background-size: 100%;
    background-repeat: repeat-x;
    background-position: 100% 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
    .visit_module__column a[href^='mailto:'], .visit_module__column a[href^='tel:'] {
      text-decoration: none;
      color: black;
      background: none; }
      .visit_module__column a[href^='mailto:']:hover, .visit_module__column a[href^='tel:']:hover {
        text-decoration: none;
        -webkit-print-color-adjust: exact;
        background-size: 100%;
        background-repeat: repeat-x;
        background-position: 100% 0;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
  .visit_module__column p {
    margin: 0;
    padding: 0; }
    .visit_module__column p:not(:last-child) {
      margin-bottom: 1em; }
  @media all and (max-width: 739px) {
    .visit_module__column br + small {
      margin-top: 0.49rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .visit_module__column br + small {
      margin-top: 0.49rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .visit_module__column br + small {
      margin-top: 0.64rem; } }
  @media (min-width: 1280px) {
    .visit_module__column br + small {
      margin-top: 0.72rem; } }
  .visit_module__column small {
    display: block; }
    @media all and (max-width: 739px) {
      .visit_module__column small {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .visit_module__column small {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .visit_module__column small {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1280px) {
      .visit_module__column small {
        font-size: 1.62rem;
        line-height: 1.98rem;
        letter-spacing: 0; } }
    .visit_module__column small a {
      text-decoration: none;
      color: black;
      text-decoration: none;
      -webkit-print-color-adjust: exact;
      background-size: 100%;
      background-repeat: repeat-x;
      background-position: 100% 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
      .visit_module__column small a[href^='mailto:'], .visit_module__column small a[href^='tel:'] {
        text-decoration: none;
        color: black;
        background: none; }
        .visit_module__column small a[href^='mailto:']:hover, .visit_module__column small a[href^='tel:']:hover {
          text-decoration: none;
          -webkit-print-color-adjust: exact;
          background-size: 100%;
          background-repeat: repeat-x;
          background-position: 100% 0;
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
  .visit_module__column ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .visit_module__column ul > li {
      margin: 0;
      padding: 0; }
    .visit_module__column ul li {
      padding-left: 1em; }
      .visit_module__column ul li:before {
        content: '– ';
        margin-left: -1em; }
  .visit_module__column code,
  .visit_module__column sup {
    line-height: 0 !important; }
    @media all and (max-width: 739px) {
      .visit_module__column code,
      .visit_module__column sup {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .visit_module__column code,
      .visit_module__column sup {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .visit_module__column code,
      .visit_module__column sup {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1280px) {
      .visit_module__column code,
      .visit_module__column sup {
        font-size: 1.62rem;
        line-height: 1.98rem;
        letter-spacing: 0; } }
  .visit_module__column sup {
    font-size: 0.65em; }
