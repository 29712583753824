@mixin modulor-font {
  font-family: map-deep-get($modulor-font, font-family);
  letter-spacing: map-deep-get($modulor-font, letter-spacing);
}

@mixin modulor-font-mono {
  font-family: map-deep-get($modulor-font-mono, font-family);
  font-size: map-deep-get($modulor-font-mono, relative-font-size);
  letter-spacing: map-deep-get($modulor-font-mono, letter-spacing);
}

@mixin modulor-font-size($size) {
  @include modulor-breakpoints {
    font-size: map-deep-get($typography, $size, font-size);
    line-height: map-deep-get($typography, $size, line-height);
  }
}
