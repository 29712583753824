@mixin ag-book-bq {
  font-family: ag-book-pro, sans-serif;
  font-weight: 400;
  font-style: normal;

  em, i {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: italic;
  }
}
