@mixin is_edit_mode {
  body.is_edit_mode & {
    @content;
  }
}

@mixin is_not_edit_mode {
  body:not(.is_edit_mode) & {
    @content;
  }
}
