@mixin web_module__is_edit_mode {
  .modulor_web_module__is_edit_mode & {
    @content;
  }
}

@mixin web_module__is_not_edit_mode {
  div:not(.modulor_web_module__is_edit_mode) & {
    @content;
  }
}
