.event_header_module__heading__daily_occurrence .current_year:not(.from):not(.to) .en .day.d + .sep,
.event_header_module__heading__daily_occurrence .current_year:not(.from):not(.to) .en .year.Y {
  display: none; }

.event_header_module__heading__daily_occurrence .current_year:not(.from):not(.to) .de.from .month.b + .sep {
  display: inline-block; }

.event_header_module__heading__daily_occurrence .current_year:not(.from):not(.to) .de .year.Y {
  display: none; }

.event_header_module__heading__daily_occurrence .en.current_year:not(.from):not(.to) .day.d + .sep,
.event_header_module__heading__daily_occurrence .en.current_year:not(.from):not(.to) .year.Y {
  display: none; }

.event_header_module__heading__daily_occurrence .de.current_year:not(.from):not(.to) .year.Y {
  display: none; }

.event_header_module__heading__daily_occurrence .whole_hour.de .hours.H + .sep,
.event_header_module__heading__daily_occurrence .whole_hour.de .minutes.M {
  display: none; }

.event_header_module__heading__daily_occurrence .whole_hour.en .hours.I + .sep,
.event_header_module__heading__daily_occurrence .whole_hour.en .minutes.M {
  display: none; }

.event_header_module__heading__daily_occurrence .same_day .de.from .minutes.M + .str {
  display: none; }

.event_header_module__heading__daily_occurrence .same_day.same_meridian .en.from .minutes.M + .sep,
.event_header_module__heading__daily_occurrence .same_day.same_meridian .en.from .ampm {
  display: none; }

.event_header_module__heading__daily_occurrence .same_day .to .day.a,
.event_header_module__heading__daily_occurrence .same_day .to .day.a + .sep {
  display: none; }

.event_header_module__heading__daily_occurrence .same_day .to .day.d,
.event_header_module__heading__daily_occurrence .same_day .to .day.d + .sep {
  display: none; }

.event_header_module__heading__daily_occurrence .same_day .to .month.b,
.event_header_module__heading__daily_occurrence .same_day .to .month.b + .sep {
  display: none !important; }

.event_header_module__heading__daily_occurrence .same_day .to .year.Y,
.event_header_module__heading__daily_occurrence .same_day .to .year.Y + .sep {
  display: none; }

.event_header_module__heading__daily_occurrence__past {
  color: var(--color-gray, #c7c7c7); }
  [data-past] .event_header_module__heading__daily_occurrence__past {
    color: black; }
