@import 'partials';

$breakpoints: (xs: (query: "all", exclusive_query: "all and (max-width: 739px)", columns: 2), s: (query: "(min-width: 740px)", exclusive_query: "(min-width: 740px) and (max-width: 999px)", columns: 4), m: (query: "(min-width: 1000px)", exclusive_query: "(min-width: 1000px) and (max-width: 1279px)", columns: 4), l: (query: "(min-width: 1280px)", exclusive_query: "(min-width: 1280px)", columns: 5));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Museum, anders_wohnen: Anders-Wohnen, cafe: Museum/Kaiser-Wilhelm-Museum/Cafe, collection: Collection, editions: Visit/Shop/Editions, education: Education, engagement: Engagement, exhibitions: Exhibitions, friends: Friends, press: Press, program: Program, publications: Visit/Shop/Publications, root: Index, shop: Visit/Shop, visit: Visit, privacy_policy: Privacy-Policy);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.announcement_module__buttons__nav {
  @include ag-book-bq;
  @include font-size(default);

  position: absolute;

  @include breakpoints {
    right: $web-module-padding;
  }
}

.announcement_module__buttons__button {
  @include reset-button;

  width: 4em;
  height: 4em;

  outline: 0;

  &:not(:last-child) {
    margin-right: 1em;
  }

  svg {
    overflow: visible;
    width: 90%;

    line,
    polygon,
    polyline {
      fill: none;
      stroke-width: 1.0rem;
      stroke: color(black);

      @include breakpoints(m) {
        stroke-width: 0.75rem;
      }

      @include breakpoints(s) {
        stroke-width: 0.75rem;
      }

      @include breakpoints(xs) {
        stroke-width: 0.75rem;
      }
    }
  }

  &.announcement_module__buttons__button__inactive {

    line,
    polygon,
    polyline {
      stroke: var(--color-gray, color(gray));

      [data-past] & {
        stroke: var(--color-gray, color(gray-dark));
      }
    }
  }
}
