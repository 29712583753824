@import 'partials';

$breakpoints: (xs: (query: "all", exclusive_query: "all and (max-width: 739px)", columns: 2), s: (query: "(min-width: 740px)", exclusive_query: "(min-width: 740px) and (max-width: 999px)", columns: 4), m: (query: "(min-width: 1000px)", exclusive_query: "(min-width: 1000px) and (max-width: 1279px)", columns: 4), l: (query: "(min-width: 1280px)", exclusive_query: "(min-width: 1280px)", columns: 5));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: Museum, anders_wohnen: Anders-Wohnen, cafe: Museum/Kaiser-Wilhelm-Museum/Cafe, collection: Collection, editions: Visit/Shop/Editions, education: Education, engagement: Engagement, exhibitions: Exhibitions, friends: Friends, press: Press, program: Program, publications: Visit/Shop/Publications, root: Index, shop: Visit/Shop, visit: Visit, privacy_policy: Privacy-Policy);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);
.announcement_module__content {
  @include ag-book-bq;
  @include font-size(default);
}

.announcement_module__heading {
  @include reset-header;

  @include ag-book-bq;
  @include font-size(large);

  &:not(:last-child) {
    @include breakpoints {
      margin-bottom: $default-line-height;
    }
  }
}

.announcement_module__items {
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  min-height: 100%;

  @include breakpoints {
    margin-right: -$web-module-padding;
    margin-left: -$web-module-padding;
  }
}

.announcement_module__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: .5rem;

  min-height: 100%;

  @include breakpoints {
    width: calc(#{fluid-col(2, $columns)});
    padding-right: $web-module-padding;
    padding-left: $web-module-padding;
  }

  @include breakpoints(s xs) {
    width: 100%;
  }
}

.announcement_module[data-item-count="1"] {
  .announcement_module__items {
    margin-left: 0;
    margin-right: 0;
  }

  .announcement_module__item {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
}

.announcement_module__attachment_images {
  @include reset-list;

  aspect-ratio: var(--min-aspect-ratio, 1);

  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
}

a+.announcement_module__heading {
  @include breakpoints {
    margin-top: $default-line-height/2;
  }
}

.announcement_module__attachment_image {
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex: 1;

  width: 100%;
}

.announcement_module__attachment_image__figure {
  margin: 0;
  padding: 0;
}

.announcement_module__content .flickity-page-dots {
  @include ag-book-bq;
  @include font-size(default);

  margin: 0;
  padding: 0;

  display: flex;
  gap: .25em;
  justify-content: center;
}

.announcement_module__content .flickity-page-dots .dot {
  color: var(--color-gray, color(gray));
  text-stroke: none;
  -webkit-text-stroke: none;
  opacity: initial;
  margin: 0;
  padding: 0;

  &:hover {
    &:before {
      color: color(black);
    }
  }

  &:before {
    content: " ";
    width: .5em;
    height: .5em;
    background-color: currentColor;
    border-radius: 100%;
  }

  &.is-selected {
    color: color(black);
  }
}

.announcement_module__body {
  @include default-typography;
}

.announcement_module__related_pages {
  @include reset-list;

  margin-top: $default-line-height;

  a {
    @include link(underline, default);
  }
}
