@mixin partners {
  div.partner {
    display: inline-block;
    vertical-align: middle;

    margin-bottom: 0.5em;
    &:not(:last-child) { margin-right: 1em; }

    width: calc(1 / var(--aspect-ratio) * 2.5em);

    svg {
      overflow: visible;
    }

    a {
      background: none;
      display: block;
    }
  }
}
