@charset "UTF-8";
.team_module__body {
  font-family: ag-book-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  column-count: 5;
  column-fill: balance; }
  .team_module__body em, .team_module__body i {
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: italic; }
  @media all and (max-width: 739px) {
    .team_module__body {
      font-size: 1.44rem;
      line-height: 1.76rem;
      letter-spacing: 0; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .team_module__body {
      font-size: 1.44rem;
      line-height: 1.76rem;
      letter-spacing: 0; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .team_module__body {
      font-size: 1.44rem;
      line-height: 1.76rem;
      letter-spacing: 0; } }
  @media (min-width: 1280px) {
    .team_module__body {
      font-size: 1.62rem;
      line-height: 1.98rem;
      letter-spacing: 0; } }
  .team_module__body h1, .team_module__body h2, .team_module__body h3, .team_module__body h4, .team_module__body h5, .team_module__body h6 {
    padding: 0;
    margin: 0;
    font-family: ag-book-pro, sans-serif;
    font-weight: 400;
    font-style: normal; }
    .team_module__body h1:not(:last-child), .team_module__body h2:not(:last-child), .team_module__body h3:not(:last-child), .team_module__body h4:not(:last-child), .team_module__body h5:not(:last-child), .team_module__body h6:not(:last-child) {
      margin-bottom: 1em; }
    .team_module__body h1 em, .team_module__body h1 i, .team_module__body h2 em, .team_module__body h2 i, .team_module__body h3 em, .team_module__body h3 i, .team_module__body h4 em, .team_module__body h4 i, .team_module__body h5 em, .team_module__body h5 i, .team_module__body h6 em, .team_module__body h6 i {
      font-family: ag-book-pro, sans-serif;
      font-weight: 400;
      font-style: italic; }
    @media all and (max-width: 739px) {
      .team_module__body h1, .team_module__body h2, .team_module__body h3, .team_module__body h4, .team_module__body h5, .team_module__body h6 {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .team_module__body h1, .team_module__body h2, .team_module__body h3, .team_module__body h4, .team_module__body h5, .team_module__body h6 {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .team_module__body h1, .team_module__body h2, .team_module__body h3, .team_module__body h4, .team_module__body h5, .team_module__body h6 {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1280px) {
      .team_module__body h1, .team_module__body h2, .team_module__body h3, .team_module__body h4, .team_module__body h5, .team_module__body h6 {
        font-size: 1.62rem;
        line-height: 1.98rem;
        letter-spacing: 0; } }
    .team_module__body h1:not(:first-child), .team_module__body h2:not(:first-child), .team_module__body h3:not(:first-child), .team_module__body h4:not(:first-child), .team_module__body h5:not(:first-child), .team_module__body h6:not(:first-child) {
      margin-top: 2em; }
  .team_module__body h1, .team_module__body h2 {
    text-transform: uppercase; }
  .team_module__body a {
    text-decoration: none;
    color: black;
    text-decoration: none;
    -webkit-print-color-adjust: exact;
    background-size: 100%;
    background-repeat: repeat-x;
    background-position: 100% 0;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
    .team_module__body a[href^='mailto:'], .team_module__body a[href^='tel:'] {
      text-decoration: none;
      color: black;
      background: none; }
      .team_module__body a[href^='mailto:']:hover, .team_module__body a[href^='tel:']:hover {
        text-decoration: none;
        -webkit-print-color-adjust: exact;
        background-size: 100%;
        background-repeat: repeat-x;
        background-position: 100% 0;
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
  .team_module__body p {
    margin: 0;
    padding: 0; }
    .team_module__body p:not(:last-child) {
      margin-bottom: 1em; }
  @media all and (max-width: 739px) {
    .team_module__body br + small {
      margin-top: 0rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .team_module__body br + small {
      margin-top: 0rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .team_module__body br + small {
      margin-top: 0rem; } }
  @media (min-width: 1280px) {
    .team_module__body br + small {
      margin-top: 0rem; } }
  .team_module__body small {
    display: block; }
    @media all and (max-width: 739px) {
      .team_module__body small {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .team_module__body small {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .team_module__body small {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1280px) {
      .team_module__body small {
        font-size: 1.62rem;
        line-height: 1.98rem;
        letter-spacing: 0; } }
    .team_module__body small a {
      text-decoration: none;
      color: black;
      text-decoration: none;
      -webkit-print-color-adjust: exact;
      background-size: 100%;
      background-repeat: repeat-x;
      background-position: 100% 0;
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
      .team_module__body small a[href^='mailto:'], .team_module__body small a[href^='tel:'] {
        text-decoration: none;
        color: black;
        background: none; }
        .team_module__body small a[href^='mailto:']:hover, .team_module__body small a[href^='tel:']:hover {
          text-decoration: none;
          -webkit-print-color-adjust: exact;
          background-size: 100%;
          background-repeat: repeat-x;
          background-position: 100% 0;
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 0, black 0, black 0.075em, rgba(0, 0, 0, 0) 0.075em); }
  .team_module__body ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .team_module__body ul > li {
      margin: 0;
      padding: 0; }
    .team_module__body ul li {
      padding-left: 1em; }
      .team_module__body ul li:before {
        content: '– ';
        margin-left: -1em; }
  .team_module__body code,
  .team_module__body sup {
    line-height: 0 !important; }
    @media all and (max-width: 739px) {
      .team_module__body code,
      .team_module__body sup {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .team_module__body code,
      .team_module__body sup {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .team_module__body code,
      .team_module__body sup {
        font-size: 1.44rem;
        line-height: 1.76rem;
        letter-spacing: 0; } }
    @media (min-width: 1280px) {
      .team_module__body code,
      .team_module__body sup {
        font-size: 1.62rem;
        line-height: 1.98rem;
        letter-spacing: 0; } }
  .team_module__body sup {
    font-size: 0.65em; }
  @media all and (max-width: 739px) {
    .team_module__body {
      column-gap: 2.25rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .team_module__body {
      column-gap: 2.25rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .team_module__body {
      column-gap: 2.4rem; } }
  @media (min-width: 1280px) {
    .team_module__body {
      column-gap: 2.7rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .team_module__body {
      column-count: 4; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .team_module__body {
      column-count: 3; } }
  @media all and (max-width: 739px) {
    .team_module__body {
      column-count: 2; } }
  .team_module__body a[href^='mailto:'] {
    overflow-wrap: break-word; }
  .team_module__body p {
    break-inside: avoid; }
  .team_module__body .text_module__detail {
    display: none; }
  .team_module__body .text_module__button_to_more__input:checked + .text_module__detail {
    display: block;
    margin-top: 1em; }
