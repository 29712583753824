@mixin modulor-border($name) {
  @if $name == 'div' {
    border: modulor-border();
    border-radius: modulor-unit(radius);
  }

  @if $name == 'tab' {
    border: modulor-border();
    border-radius: modulor-unit(radius);
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @if $name == 'button' {
    border: modulor-border();
    border-radius: modulor-unit(radius);
  }

  @if $name == 'dialog-header' {
    border-bottom: modulor-border();
  }

  @if $name == 'dropdown' {
    border: modulor-border();
    border-radius: modulor-unit(radius);
  }

  @if $name == 'dropdown-group' {
    border-top: modulor-border();
  }

  @if $name == 'input' {
    border: modulor-border();
    border-radius: modulor-unit(radius);
  }

  @if $name == 'table' {
    border: modulor-border();
  }

  @if $name == 'table-tbody' {
    border-top: modulor-border();
  }

  @if $name == 'web-module' {
    border: modulor-border();
    border-radius: modulor-unit(radius);
  }
}
