// @mixin breakpoint-col(width, 4);
// @mixin breakpoint-col(width, 4, s xs);
//
// @mixin modulor-breakpoint-col(width, 4);
// @mixin modulor-breakpoint-col(width, 4, s xs);

@mixin breakpoint-col($prop, $args, $names: map-keys($breakpoints), $breakpoint-map: $breakpoints) {
  $multiplier: 1;

  $should-have-negative-value: (str-slice($prop, 0, 1) == '-');
  @if $should-have-negative-value {
    $multiplier: -1;
    $prop: str-slice($prop, 2, -1);
  }

  @each $name in $names {
    $breakpoint: map-get($breakpoint-map, $name);
    $query: "#{map-get($breakpoint, exclusive_query)}";
    $columns: map-get($breakpoint, columns);

    @if str-length($query) > 0 {
      @media #{$query} {
        /* #{$name} */
        #{$prop}: $multiplier * fluid-col($args, $columns);
      }
    } @else {
      #{$prop}: $multiplier * fluid-col($args, $columns);
    }
  }
}
