.event_list_module__content[data-view-option="related_upcoming"] {
  grid-column-start: span 3; }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .event_list_module__content[data-view-option="related_upcoming"] {
      grid-column-start: span 2; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .event_list_module__content[data-view-option="related_upcoming"] {
      grid-column-start: span 2; } }
  @media all and (max-width: 739px) {
    .event_list_module__content[data-view-option="related_upcoming"] {
      grid-column-start: span 2; } }
  @media all and (max-width: 739px) {
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__section:not(:last-child) {
      margin-bottom: 2.25rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__section:not(:last-child) {
      margin-bottom: 2.25rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__section:not(:last-child) {
      margin-bottom: 2.4rem; } }
  @media (min-width: 1280px) {
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__section:not(:last-child) {
      margin-bottom: 2.7rem; } }
  @media all and (max-width: 739px) {
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__section__group_key__month {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__section__group_key__month {
      font-size: 1.95rem;
      line-height: 2.25rem;
      letter-spacing: 0; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__section__group_key__month {
      font-size: 2.08rem;
      line-height: 2.4rem;
      letter-spacing: 0; } }
  @media (min-width: 1280px) {
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__section__group_key__month {
      font-size: 2.34rem;
      line-height: 2.7rem;
      letter-spacing: 0; } }
  @media all and (max-width: 739px) {
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__section__group_key__month {
      margin-bottom: 2.25rem; } }
  @media (min-width: 740px) and (max-width: 999px) {
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__section__group_key__month {
      margin-bottom: 2.25rem; } }
  @media (min-width: 1000px) and (max-width: 1279px) {
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__section__group_key__month {
      margin-bottom: 2.4rem; } }
  @media (min-width: 1280px) {
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__section__group_key__month {
      margin-bottom: 2.7rem; } }
  .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails {
    list-style: none;
    margin: 0;
    padding: 0; }
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails > li {
      margin: 0;
      padding: 0; }
    @media all and (max-width: 739px) {
      .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails {
        margin-left: -2.25rem;
        margin-bottom: -2.25rem; }
        .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails .event_page__thumbnail {
          margin-bottom: 2.25rem;
          padding-left: 2.25rem;
          vertical-align: top; }
        .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails .event_page__thumbnail {
          display: inline-block; } }
    @media (min-width: 740px) and (max-width: 999px) {
      .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails {
        margin-left: -2.25rem;
        margin-bottom: -2.25rem; }
        .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails .event_page__thumbnail {
          margin-bottom: 2.25rem;
          padding-left: 2.25rem;
          vertical-align: top; }
        .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails .event_page__thumbnail {
          display: inline-block; } }
    @media (min-width: 1000px) and (max-width: 1279px) {
      .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails {
        margin-left: -2.4rem;
        margin-bottom: -2.4rem; }
        .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails .event_page__thumbnail {
          margin-bottom: 2.4rem;
          padding-left: 2.4rem;
          vertical-align: top; }
        .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails .event_page__thumbnail {
          display: inline-block; } }
    @media (min-width: 1280px) {
      .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails {
        margin-left: -2.7rem;
        margin-bottom: -2.7rem; }
        .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails .event_page__thumbnail {
          margin-bottom: 2.7rem;
          padding-left: 2.7rem;
          vertical-align: top; }
        .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails .event_page__thumbnail {
          display: inline-block; } }
    .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails .event_page__thumbnail {
      width: 33.33333%; }
      @media (min-width: 1000px) and (max-width: 1279px) {
        .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails .event_page__thumbnail {
          width: 50%; } }
      @media (min-width: 740px) and (max-width: 999px) {
        .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails .event_page__thumbnail {
          width: 100%; } }
      @media all and (max-width: 739px) {
        .event_list_module__content[data-view-option="related_upcoming"] .event_list_module__thumbnails .event_page__thumbnail {
          width: 100%; } }
  .event_list_module__content[data-view-option="related_upcoming"] .event_page__thumbnail__date_range__past {
    color: var(--color-gray, #c7c7c7); }
    [data-past] .event_list_module__content[data-view-option="related_upcoming"] .event_page__thumbnail__date_range__past {
      color: black; }
